module.exports = {
  brand: 'mcom',
  storePickupInfoLink: 'https://customerservice-macys.com/articles/store-pick-up-information',
  sddDetailsLink: 'https://customerservice-macys.com/articles/what-is-same-day-next-day-delivery',
  sddInfoLink: 'https://customerservice-macys.com/articles/what-is-same-day-next-day-delivery',
  pickItUp: 'Pick it up your way',
  learnMoreSdd: 'Learn more about ',
  learnMorePickup: 'Learn more about Store Pickup',
  stillQuestion: 'Still have questions?',
  noMatchesFound: '0 matches found',
};
