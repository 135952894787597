const REPLACE_SELECTED_VALUES = 'replaceSelectedValues';
const ADD_CHECKBOX_VALUES = 'addCheckboxValues';
const REMOVE_CHECKBOX_VALUES_BY_VALUE = 'removeCheckboxByValue';
const REMOVE_CUSTOM_CHECKBOX_VALUES = 'removeCustomCheckboxValues';
const UPDATE_SORTBY_SELECTED = 'updateSortbySelected';
const ADD_FACET_OPTIONS = 'addFacetOptions';
const ADD_SORT_BY_OPTIONS = 'addSortByOptions';
const SET_DESELECTION_PILLS_ORDER_NAMES = 'setDeselectionPillsOrderNames';
const CLEAR_ALL_SELECTED_VALUES = 'clearAllSelectedValues';

export {
  UPDATE_SORTBY_SELECTED, REMOVE_CHECKBOX_VALUES_BY_VALUE, ADD_CHECKBOX_VALUES,
  REPLACE_SELECTED_VALUES, ADD_FACET_OPTIONS, ADD_SORT_BY_OPTIONS,
  REMOVE_CUSTOM_CHECKBOX_VALUES, SET_DESELECTION_PILLS_ORDER_NAMES, CLEAR_ALL_SELECTED_VALUES,
};
