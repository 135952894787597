/**
 * Represents a device with a specific type.
 * @typedef {string} IDeviceType
 * @enum {'DESKTOP' | 'PHONE' | 'TABLET' | 'POS'}
 */

/**
 * Represents shopping mode types.
 * @typedef {string} IShoppingMode
 * @enum {'SITE' | 'WEDDING_REGISTRY'}
 */

/**
 * Configuration object representing page settings.
 * @typedef {Object} IPageParams
 * @property {IDeviceType} deviceType - The type of the device. Can be 'DESKTOP', 'PHONE', 'TABLET', 'POS'.
 * @property {boolean} isDomestic - Indicates if the user is from the US.
 * @property {boolean} isRegistry - Indicates if the shopping mode is 'WEDDING_REGISTRY'.
 * @property {string} regionCode - The region code of the user.
 * @property {IShoppingMode} shoppingMode - The shopping mode of the user. Can be 'SITE', 'WEDDING_REGISTRY'.
 * @property {string} currencyCode - The currency code relevant to the user.
 */

/**
 * Configuration object representing enviroment settings.
 * @typedef {Object} IEnvParams
 * @property {string} brand - The entry point from the environment variable (e.g., 'bcom', 'mcom').
 * @property {boolean} isBcom - True if the brand is 'bcom', otherwise false.
 * @property {boolean} isMcom - True if the brand is 'mcom', otherwise false.
 * @property {string} assetHost - The asset host URL from the environment variable.
 * @property {boolean} tagMngrEnabled - True if tag manager is enabled from the environment variable, otherwise false.
 * @property {string} tagMngrAccount - The tag manager account from the environment variable.
 * @property {string} tagMngrEnv - The tag manager environment from the environment variable.
 * @property {boolean} tealiumSyncEnabled - True if Tealium sync is enabled from the environment variable, otherwise false.
 */

/**
 * Route object
 * @typedef {Object} IRoute
 * @property {string} fullPath - The full path of the route (including query and hash).
 * @property  {string} hash - Hash of the route, if it has it
 * @property  {Array<Object>} matched - An array of route objects that the current route matched
 * @property  {Object} meta - Any custom metadata associated with the route
 * @property  {string} name - The name of the route
 * @property  {Object} params - Object that represents parameters of the route
 * @property  {string} path - Basic path of the current route
 * @property  {Object<string,string>} query - Object containing query parameters
 * @property  {boolean} redirectedFrom - True if we are redirected from (this is set only when a redirect happens)
 */

/**
 * Custom error object containing functions for handling the errors
 * @typedef {Object} ICustomError
 * @property {Function} listenErrorCaptured
 * @property {Function} clearError - Function for filtering errors array
 * @property {Function} showModalError - Function for showing the error modal
 * @property {ReadonlyArray<Error>} errors - Readonly array of error objects
 */

/**
 * @typedef {Object} IExperimentId
 * @property {import('vue').ComputedRef<string[]>} experimentIds - Computed property containing an array of experiment ids
 */

/**
 * KillSwitch object containing information about killswitches
 * @typedef {Object} IKillSwitch
 * @property {string} brand - Name of the brand
 * @property {boolean} isBigTicketCXSearchAndBrowseTypeEnabled
 * @property {boolean} isSiteMonHeartFunctionalityEnabled
 * @property {boolean} contextualWidgetEnabled
 * @property {boolean} isSalesFloorSearchEnabled
 * @property {boolean} uniqueSearchPatternEnabled
 * @property {boolean} pzoBadgingEnabled
 * @property {boolean} scrollyCatsEnabled
 * @property {boolean} priceSimplificationEnabled
 * @property {boolean} newBreadcrumbStructureEnabled
 * @property {boolean} loyaltyBadgeEnhancementEnabled
 * @property {boolean} removeToolTipOnFGWPBadge
 */

/**
 * Object representing single modal object
 * @typedef {Object} ModalObject
 * @property {string} id - Id selector for modal
 * @property {string} width - Width of the modal
 * @property {Object} component - Component for rendering
 */

/**
 * Custom modal object containing modal objects and modal handling functions
 * @typedef {Object} IModal
 * @property {Function} showModal
 * @property {Function} closeModal
 * @property {import('vue').ComputedRef<ModalObject>} modal - First modal object of modals array
 * @property {import('vue').ShallowRef<ModalObject[]>} modals - Array of modal objects
 * @property {import('vue').ComputedRef<boolean>} isModalAvailable
 */

/**
 * @typedef {Object} IPzoObject
 * @property {Function} doPzoStorageExistsForThisUserId
 * @property {Function} setPzoStorage
 */

/**
 * @typedef {Object} IProgramaticScroll
 * @property {import('vue').Ref<boolean>} isProgrammaticScroll
 * @property {Function} setFlagProgrammaticScroll
 */

/**
 * @typedef {Object} ITierInfo
 * @property {string} tierName
 * @property {string} spendToKeepCurrent
 * @property {string} spendToNextUpgrade
 * @property {string} yearToDateSpend
 * @property {boolean} attuEligible
 */

/**
 * @typedef {Object} IRewardCard
 * @property {string} cardType
 * @property {string} expiryDate
 * @property {boolean} expirySoon
 * @property {string} maskedCardNumber
 * @property {number} rewardMoney
 */

/**
 * @typedef {Object} IRewardsInfo
 * @property {number} pointsThresholdForAward
 * @property {number} awardValue
 * @property {number} pointsToNextAward
 * @property {number} currentPoints
 * @property {number} pendingPoints
 * @property {string} totalStarRewardCardsBalance
 * @property {number} progressBarPercent
 * @property {boolean} anyRewardCardExpiringSoon
 * @property {boolean} showExclusionsAndDetailsLink
 * @property {IRewardCard[]} rewardCards
 * @property {number} minExpiryDayRewardCard
 */

/**
 * @typedef {Object} ILoyalty
 * @property {string} walletCardsIndicator
 * @property {ITierInfo} tierInfo
 * @property {IRewardsInfo} rewardsInfo
 * @property {string} encryptedLoyaltyId
 */

module.exports = {};
