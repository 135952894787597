import { useStore } from 'vuex';
import { useGoogleAdsManager } from '@common-vue/site-monetization';
import useMeta from '@modules/discovery/composables/useMeta';
import useEnvParams from '@composables/useEnvParams';
import usePageType from './usePageType';
import useDevice from './useDevice';

let tagParams;
const getSiteMonGAMTagsOnFacetSelection = () => {
  useGoogleAdsManager.initGAMTags(tagParams);
};

const initSiteMonGAMTags = () => {
  const { isPhone } = useDevice();
  const pageType = usePageType();
  const meta = useMeta();
  const store = useStore();
  const { brand, tagMngrEnv } = useEnvParams();
  const sitemonPageType = {
    BROWSE: 'browse', SPLASH: 'catsplash', SUB_SPLASH: 'subsplash', SEARCH: 'search',
  };
  tagParams = {
    store,
    device: !isPhone ? 'desktop' : 'mobile',
    page: sitemonPageType[pageType.sitePageType],
    enableGAMAdsFromApp: meta.value?.properties?.enableGAMAdsFromApp || false,
    enableGAMImpressionTags: meta.value?.properties?.enableGAMImpressionTags || false,
    enableGAMClickTags: meta.value?.properties?.enableGAMClickTags || false,
    tagEnv: tagMngrEnv,
    brand,
    searchKeyword: meta?.value?.context?.searchTerm || '',
    catID: meta?.value?.analytics?.data?.categoryID || '',
    topLevelCatID: meta?.value?.analytics?.data?.topLevelCategoryID || '',
    categoryTreeIDs: meta?.value?.analytics?.data?.categoryTreeIDs || '',
  };
  useGoogleAdsManager.initGAMTags(tagParams);
};

export default function useSiteMonGAMTags() {
  return {
    initSiteMonGAMTags,
    getSiteMonGAMTagsOnFacetSelection,
  };
}
