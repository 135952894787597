const getExperimentsMap = (experiments) => experiments.reduce((acc, cur) => {
  const [key, value] = cur.split('-');
  acc[key] = value || '';
  return acc;
}, {});

/**
 * Returns new array of experiments ids, which contains source experiment ids and additional experiment ids.
 * @param {Array} sourceExperimentIds - Array of source experiment ids
 * @param {Array} additionalExperimentIds - Array of or additional experiment ids
 * @returns
 */
function combineExperimentIds(sourceExperimentIds, additionalExperimentIds) {
  const allExperiments = sourceExperimentIds.concat(additionalExperimentIds);
  const experimentsMap = getExperimentsMap(allExperiments);

  return Object.entries(experimentsMap).map(([key, value]) => `${key}-${value}`);
}

module.exports = combineExperimentIds;
