<template>
  <ModalOverlay
    v-if="isModalAvailable"
    :id="modal?.id"
    :width="modal?.width"
    :title="modal?.title"
    @close="closeModal($event); clearError($event)"
  >
    <template #body>
      <component
        :is="modal?.body"
        :id="modal?.id"
      />
    </template>
    <template #footer>
      <component :is="modal?.footer" />
    </template>
  </ModalOverlay>
</template>

<script setup>
import { ModalOverlay } from '@atomic-ui/modalOverlay';
import { useModal } from '@composables/useModal';
import { useError } from '@composables/useError';

const { isModalAvailable, modal, closeModal } = useModal();
const { clearError } = useError();

</script>
