// eslint-disable-next-line import/prefer-default-export
export const deviceTypes = {
  PHONE: 'PHONE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const BREAKPOINTS = {
  [deviceTypes.PHONE]: { max: 599 },
  [deviceTypes.TABLET]: { min: 600, max: 1023 },
  [deviceTypes.DESKTOP]: { min: 1024 },
};

export const deviceProductsPerRow = {
  [deviceTypes.PHONE]: 2,
  [deviceTypes.TABLET]: 4,
  [deviceTypes.DESKTOP]: 4,
};

export const pageTypes = {
  SUBSPLASH: 'SUB_SPLASH',
  BROWSE: 'BROWSE',
  SEARCH: 'SEARCH',
  SPLASH: 'SPLASH',
  BRANDINDEX: 'BRAND_INDEX',
};

export const pageTypesLiteral = {
  subSplash: 'subSplash',
  zeroSearchResults: 'zeroSearchResults',
  browse: 'browse',
  search: 'search',
  catSplash: 'catSplash',
  brandIndex: 'brandIndex',
};

export const midContClassNames = {
  LARGE12: 'large-12',
  LARGE9: 'large-9',
};

export const phoneNumbers = {
  mcom: '1-800-289-6229',
  bcom: '1-800-777-0000',
};

export const BOPS_NAME = 'Upc_bops_purchasable';
export const SDD_FACET = 'Upc_sdd_purchasable';
