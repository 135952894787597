import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Cookie } from '@common-vue/util/src/modules/cookie';
import { useRoute } from 'vue-router';
import { BOPS_NAME, SDD_FACET } from '@utils/constants';
import { brand } from 'config';

const FALLBACK_LOCATION_NUMBER = '0000';

const $http = require('@vue-util/http');

let store;

const discoveryCLSEnabled = function () {
  const discoveryEnabled = computed(() => store.getters.getPageDataProperties.discoveryCLSEnabled);
  return discoveryEnabled.value;
};

const getStoreUrl = (CSLCookie, SFLCookie) => {
  let url = '';

  const cslSflCookie = [CSLCookie, SFLCookie].filter(Boolean).join(',');
  url = discoveryCLSEnabled() ? `/xapi/discover/v1/locations/locnum?locationNumber=${cslSflCookie}` : `/api/store/v2/stores/${cslSflCookie}?_fields=bopsFreePickUpMessage,curbsideFreePickUpMessage,sddMessage,locationNumber,sddCutOffTime,cutOffTime`;
  return url;
};

const isLoading = ref(false);

export default function useShopyourStore() {
  store = useStore();
  const route = useRoute();

  const setSysLoading = (loading = false) => {
    isLoading.value = loading;
  };

  const getOrderMessage = (cutOffTime, method) => {
    let message = '';
    let formattedCutoffTime = '12pm';
    if (cutOffTime) {
      const relativeDay = (method === 'storepickup') ? cutOffTime.pickupRelativeDay : cutOffTime.deliveryRelativeDay;
      const availableDate = (method === 'storepickup' && relativeDay === 'OTHER') ? cutOffTime.pickupDate : cutOffTime.deliveryDate;
      if (relativeDay === 'TODAY' && cutOffTime.orderTimeThreshold) {
        const hours = cutOffTime.orderTimeThreshold.hour;
        const hour12 = ((hours + 11) % 12) + 1;
        const todayOrderMsg = `Order by ${hour12}${hours >= 12 ? 'pm' : 'am'}`;
        if (method === 'storepickup') {
          message = `${brand === 'mcom' ? `<strong>${todayOrderMsg}</strong>` : `${todayOrderMsg}`} to get it ${relativeDay.toLowerCase()}`;
        } else {
          formattedCutoffTime = `${hour12}${hours >= 12 ? 'pm' : 'am'}`;
          message = `${brand === 'mcom' ? `<strong>${todayOrderMsg}</strong>, delivers ${relativeDay.toLowerCase()} with DoorDash` : `${todayOrderMsg}, delivers ${relativeDay.toLowerCase()} with DoorDash`}`;
        }
      } else if (relativeDay === 'TOMORROW') {
        if (method === 'storepickup') {
          message = `${brand === 'mcom' ? `Get it ${relativeDay.toLowerCase()}` : `Pick up ${relativeDay.toLowerCase()}`}`;
        } else {
          message = `Delivers ${relativeDay.toLowerCase()} with DoorDash`;
        }
      } else if (relativeDay === 'OTHER' && availableDate) {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const dateFormat = `${brand === 'mcom' ? `${parseInt(availableDate.month, 10)}/${availableDate.date}` : `${monthNames[parseInt(availableDate.month, 10) - 1]} ${availableDate.date}`}`;
        message = `${(method === 'storepickup') ? `Get it on ${dateFormat}` : `Delivers on ${dateFormat} with DoorDash`}`;
      }
      message = `${message !== '' && brand === 'bcom' ? `${message.replace(/[ap]m/ig, (match) => match.toUpperCase())}.` : message}`;
    }
    return { message, formattedCutoffTime };
  };

  const checkingRouteForSysPage = () => {
    try {
      const {
        params: { pathMatch },
      } = route;

      let isPickupInRoute = false;

      for (let i = 0; i < pathMatch.length; i++) {
        const splittedRoute = pathMatch[i].split(',');
        if (splittedRoute.includes('pickup-delivery')) {
          isPickupInRoute = true;
        } else if (
          isPickupInRoute && (splittedRoute.includes(BOPS_NAME)
          || splittedRoute.includes(SDD_FACET))
        ) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const addOrderMessages = (data, CSLCookie, SFLCookie) => {
    let storePickupOrderMsg;
    let sddOrderMsg;
    let sddCutoffHour;

    data.stores.store.forEach((dataStore) => {
      if (dataStore.locationNumber === CSLCookie) {
        const { message } = getOrderMessage(dataStore.cutOffTime, 'storepickup');
        storePickupOrderMsg = message;
      }

      if (dataStore.locationNumber === SFLCookie) {
        const { message, formattedCutoffTime } = getOrderMessage(dataStore.sddCutOffTime, 'sdd');
        sddOrderMsg = message;
        sddCutoffHour = formattedCutoffTime;
      }
    });

    return { storePickupOrderMsg, sddOrderMsg, sddCutoffHour };
  };

  const getCSLCookie = () => {
    const bopsPickupStore = Cookie.get('BOPSPICKUPSTORE', 'MISCGCs');
    const cslCookie = Cookie.get('CSL') || bopsPickupStore;
    if (Number.isNaN(Number(cslCookie))) {
      return Cookie.get('CSL');
    }
    return cslCookie;
  };

  const getSFLCookie = () => {
    const sflCookie = Cookie.get('SFL') || Cookie.get('CSL') || '0000';
    return sflCookie;
  };

  const getFreePickupMessage = async () => {
    let storeURl = '';
    let CSLCookie = getCSLCookie();
    let SFLCookie = getSFLCookie();
    let sddBadgeText;
    let storePickupOrderMsg;
    let sddOrderMsg;
    let sddCutoffHour;

    SFLCookie = (SFLCookie && SFLCookie !== '0000' && SFLCookie !== '') ? SFLCookie : false;
    CSLCookie = CSLCookie || false;

    storeURl = getStoreUrl(CSLCookie, SFLCookie);
    SFLCookie = SFLCookie && Number(SFLCookie);
    CSLCookie = CSLCookie && Number(CSLCookie);

    const compareCslSfl = (CSLCookie === SFLCookie);

    if ((CSLCookie || SFLCookie) && storeURl) {
      const response = await $http.get(storeURl, {
        maxRedirects: 1,
        headers: discoveryCLSEnabled() ? { 'X-Macys-Client-Id': 'MCOM', 'X-Macys-Sub-Client-Id': 'MCOM' } : { 'X-Macys-ClientId': 'NavApp' },
      });
      if (response) {
        const { data } = response;

        if (data) {
          const orderMessages = addOrderMessages(data, CSLCookie, SFLCookie);

          storePickupOrderMsg = orderMessages.storePickupOrderMsg || '';
          sddOrderMsg = orderMessages.sddOrderMsg || '';
          sddCutoffHour = orderMessages.sddCutoffHour;
        }

        if (data && data.stores && data.stores.store.length > 0 && !compareCslSfl) {
          data.stores.store.forEach((dataStore) => {
            if (dataStore.locationNumber === SFLCookie) {
              sddBadgeText = dataStore.sddMessage || 'Next-Day Delivery';
            }
          });
        } else if (compareCslSfl && data && data.stores && data.stores.store.length > 0) {
          sddBadgeText = data.stores.store[0].sddMessage || 'Next-Day Delivery';
        }
      }
    }
    return {
      sddBadgeText, storePickupOrderMsg, sddOrderMsg, sddCutoffHour,
    };
  };

  const getUpdatedQueryParams = (query, key = false, value = false) => {
    let queryUrl = query;
    const params = new URLSearchParams(query);

    if (params.has(key)) {
      delete params.delete(key);
      if (value) {
        params.append(key, value);
      }
      queryUrl = params.toString();
    } else {
      if (value && key) {
        params.append(key, value);
      }
      queryUrl = params.toString();
    }

    return queryUrl;
  };

  const setSFLCookie = () => {
    const locationNumber = FALLBACK_LOCATION_NUMBER;
    if (!Cookie.get('SFL')) {
      Cookie.set('SFL', locationNumber);
    }
  };

  const getZipcodeLocationInfo = async (zipCode) => {
    let locationInfo = null;
    let isSddAvailable = false;

    try {
      if (zipCode) {
        let info = null;
        const url = `/api/store/v2/stores/shipTo/${zipCode}`;
        const response = await $http.get(url, {
          maxRedirects: 1,
          params: {
            xapi: 1,
            _fields: 'locationNumber',
            shipMethod: 'SDD',
            httpMethod: 'GET',
          },
          headers: {
            'x-macys-clientid': 'Navapp',
          },
        });
        info = response?.data?.stores?.store;
        isSddAvailable = response?.data?.stores?.store.length > 0 || false;
        info = info ? info[0] : null;
        locationInfo = info ? info.locationNumber : null;
      }

      return { locationInfo, isSddUnAvailable: !isSddAvailable };
    } catch (error) {
      console.error('Error: ', error); // eslint-disable-line no-console
      throw error;
    }
  };

  const getPickupStoreDetails = () => {
    if (typeof window === 'undefined') return null;

    const storeLocation = window.localStorage.getItem('commonstorelocation');
    return storeLocation ? JSON.parse(storeLocation) : null;
  };

  const isEmpty = (value) => value && Object.keys(value).length === 0;

  const modifyUrlWithNewValue = (url, keyToUpdate, newValue, isTabChange = false) => {
    const routeParts = url.split('/shop/pickup-delivery/');
    const [keyValues, queryParams] = routeParts[1].split('?');
    const [categories, values] = keyValues.split('/');
    let categoryArray = categories.split(',');
    let valueArray = values.split(',');

    if (isTabChange) {
      const pageIndex = categoryArray.indexOf('Pageindex');
      categoryArray = categoryArray.filter((category) => category !== 'Pageindex');
      valueArray = pageIndex === -1 ? valueArray : valueArray.splice(pageIndex, 1);
    }

    const keyToUpdateIndex = categoryArray.indexOf(keyToUpdate);
    if (keyToUpdateIndex !== -1) {
      valueArray[keyToUpdateIndex] = newValue;
    }

    return `/shop/pickup-delivery/${categoryArray.join(',')}/${valueArray.join(',')}?${queryParams}`;
  };

  return {
    getFreePickupMessage,
    checkingRouteForSysPage,
    getUpdatedQueryParams,
    setSFLCookie,
    getCSLCookie,
    getZipcodeLocationInfo,
    getPickupStoreDetails,
    isEmpty,
    isLoading,
    setSysLoading,
    getSFLCookie,
    modifyUrlWithNewValue,
  };
}
