import { useStore } from 'vuex';
import { IEnvParams } from '@types/definitions'; // eslint-disable-line no-unused-vars

/**
 * Use env parameters from the Server.
 *
 * @returns {IEnvParams} - static object with env params.
 */
export default function useEnvParams() {
  return useStore().state.envProps;
}
