import { useStore } from 'vuex';
import { computed } from 'vue';
import { IExperimentId } from '@types/definitions'; // eslint-disable-line no-unused-vars
/**
 * Composable for getting the experiment ids
 * @returns {IExperimentId}
 */
export default function useExperimentIds() {
  const store = useStore();
  return {
    experimentIds: computed(() => store?.getters?.getExperimentIds || []),
  };
}
